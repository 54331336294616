<!-- 发布需求 -->
<template>
  <div class="container">
    <el-card>
      <el-form
        :model="ruleForm"
        ref="ruleForm"
        :rules="rules"
        label-width="100px"
        class="ruleForm"
      >
        <el-form-item
          prop="bankAccount"
          label-width="135"
          class="el-form-item-line"
        >
          <div class="tile_box">
            <div class="title">填写需求信息</div>
          </div>
        </el-form-item>
        <el-form-item label="联系人：" prop="linkman" label-width="135">
          <el-input
            v-model="ruleForm.linkman"
            style="width: 20%"
            size="small"
          ></el-input>
        </el-form-item>
        <el-form-item label="联系电话：" prop="telephone" label-width="135">
          <el-input
            v-model="ruleForm.telephone"
            style="width: 20%"
            size="small"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="施工地点："
          prop="projectProvince"
          label-width="135"
          style="width: 100%"
        >
          <div class="address_box">
            <el-select
              v-model="ruleForm.projectProvince"
              placeholder="请选择省"
              @change="provinceChange"
              size="small"
              name="projectProvince"
            >
              <el-option
                v-for="(item, index) in provinceList"
                :key="index"
                :label="item.cityName"
                :value="item.cityId"
              ></el-option>
            </el-select>
            <el-select
              v-model="ruleForm.projectCity"
              placeholder="请选择市"
              @change="cityChange"
              size="small"
              name="projectProvince"
            >
              <el-option
                v-for="(item, index) in cityList"
                :key="index"
                :label="item.cityName"
                :value="item.cityId"
              ></el-option>
            </el-select>
            <el-select
              v-model="ruleForm.projectCountry"
              placeholder="请选择区"
              @change="areaChange"
              size="small"
              name="projectProvince"
            >
              <el-option
                v-for="(item, index) in areaList"
                :key="index"
                :label="item.cityName"
                :value="item.cityId"
              ></el-option>
            </el-select>
          </div>
        </el-form-item>
        <el-form-item
          label="详细地址："
          prop="projectAddress"
          label-width="135"
        >
          <el-input
            type="textarea"
            :rows="2"
            v-model="ruleForm.projectAddress"
            placeholder="详细地址"
            style="width: 56%"
            size="small"
          ></el-input>
        </el-form-item>
        <!-- <el-form-item label="机械数量：" prop="count" label-width="135">
          <el-input
            v-model="ruleForm.count"
            style="width: 20%"
            size="small"
          ></el-input>
        </el-form-item> -->
        <el-form-item label="进场时间：" prop="workTimeStart" label-width="135">
          <el-date-picker
            v-model="ruleForm.workTimeStart"
            type="date"
            placeholder="选择日期"
            value-format=yyyy/MM/dd
            style="width: 20%"
            size="small"
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item
          label="租赁开始时间："
          prop="rentStartDate"
          label-width="135"
        >
          <el-date-picker
            size="small"
            value-format=yyyy/MM/dd
            v-model="ruleForm.rentStartDate"
            type="date"
            placeholder="选择日期"
            style="width: 20%"
          ></el-date-picker>
        </el-form-item>
        <el-form-item
          label="租赁结束时间："
          prop="rentEndDate"
          label-width="135"
        >
          <el-date-picker
            size="small"
            value-format=yyyy/MM/dd
            v-model="ruleForm.rentEndDate"
            type="date"
            placeholder="选择日期"
            style="width: 20%"
        ></el-date-picker>
        </el-form-item>
        <el-form-item label="支付方式：" prop="payTypeCode" label-width="135">
          <el-select
            size="small"
            v-model="ruleForm.payTypeCode"
            placeholder="请选择"
            style="width: 20%"
            @change="getPayType"
          >
            <el-option
              v-for="item in payTypeCodeList"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="预计工期：" prop="expectedDate" label-width="135">
          <el-input
            v-model="ruleForm.expectedDate"
            placeholder="输入天数"
            style="width: 20%"
            size="small"
          ></el-input
          ><span>&nbsp; 天</span>
        </el-form-item>
        <el-form-item label="预估工作量：" prop="workload" label-width="135">
          <el-input
            v-model="ruleForm.workload"
            style="width: 20%"
            size="small"
            @change="changeWord"
          ></el-input>
          <span>&nbsp; {{ leaseUnit }}</span>
        </el-form-item>
        <el-form-item label="施工备注：" prop="orderRemark" label-width="135">
          <el-input
            type="textarea"
            :rows="2"
            v-model="ruleForm.orderRemark"
            placeholder="项目备注"
            style="width: 56%"
            size="small"
          ></el-input>
        </el-form-item>
        <div class="total_price">
          <h4>
            合计：<span style="color: red">￥{{ price ? price : '0.00' }}</span>
          </h4>
        </div>
      </el-form>
      <div class="btn">
        <el-button @click="backCar">返回</el-button>
        <el-button @click="submit" class="submit" :loading="loading"
          >确认提交</el-button
        >
      </div>
    </el-card>
  </div>
</template>

<script>
import { getCityTree, getCitySelect } from '@/api/user'
import { vehicleOrderSubmit, getOffer } from '@/api/car'

export default {
  data() {
    const validatePhone = (rule, value, callback) => {
      const reg = /^1[3456789]\d{9}$/
      if (value === '' || !reg.test(value)) {
        return callback(new Error('请输入正确格式的手机号码 '))
      } else {
        callback()
      }
    }
    return {
      loading: false,
      ruleForm: {
        linkman: '',
        telephone: '',
        projectProvince: '',
        projectProvinceCode: '',
        projectCity: '',
        projectCityCode: '',
        projectCountry: '',
        projectCountryCode: '',
        projectAddress: '',
        // count: null,
        workTimeStart: '',
        expectedDate: '',
        orderRemark: '',
        workload: '',
        payTypeCode: '',
        rentStartDate: '',
        rentEndDate: '',
        payType: '',
      },
      provinceList: [],
      cityList: [],
      areaList: [],
      payTypeCodeList: [
        { value: '0', name: '完工付' },
        { value: '1', name: '现结' },
        { value: '2', name: '日结' },
        { value: '3', name: '月结' },
        { value: '4', name: '季付' },
      ],
      rules: {
        linkman: [{ required: true, message: '请输入联系人', trigger: 'blur' }],
        telephone: [
          { required: true, validator: validatePhone, trigger: 'blur' },
        ],
        projectProvince: [
          { required: true, message: '请选择区域', trigger: 'change' },
        ],
        projectAddress: [
          { required: true, message: '请输入详细地址', trigger: 'blur' },
        ],
        // count: [{ required: true, message: '请输入数量', trigger: 'blur' }],
        workTimeStart: [
          { required: true, message: '请选择进场时间', trigger: 'change' },
        ],
        rentStartDate: [
          { required: true, message: '请选择租赁开始时间', trigger: 'change' },
        ],
        rentEndDate: [
          { required: true, message: '请选择租赁结束时间', trigger: 'change' },
        ],
        payTypeCode: [
          { required: true, message: '请选择支付方式', trigger: 'change' },
        ],
        expectedDate: [
          { required: true, message: '请输入工期长度', trigger: 'blur' },
        ],
        orderRemark: [
          { required: true, message: '请输入施工备注', trigger: 'blur' },
        ],
        workload: [
          { required: true, message: '请输入工作量', trigger: 'blur' },
        ],
      },
      price: null,
      leaseUnit: '',
    }
  },
  created() {
    this.ruleForm.vehicleId = this.$route.query.id
    this.leaseUnit = this.$route.query.leaseUnit
  },
  mounted() {
    this.getCityTree()
  },
  methods: {
    getCityTree() {
      getCityTree().then((res) => {
        this.provinceList = res.data
      })
    },
    provinceChange(e) {
      let provinceObj = {}
      provinceObj = this.provinceList.find((item) => {
        return item.cityId === e
      })
      this.ruleForm.projectProvince = provinceObj.cityName
      this.ruleForm.projectProvinceCode = provinceObj.cityId
      getCitySelect({ cityPid: e }).then((res) => {
        this.cityList = res.data
      })
    },
    getPayType(e) {
      this.payTypeCodeList.forEach((item) => {
        if (item.value == e) {
          this.ruleForm.payType = item.name
        }
      })
    },
    cityChange(e) {
      let cityObj = {}
      cityObj = this.cityList.find((item) => {
        return item.cityId === e
      })
      this.ruleForm.projectCity = cityObj.cityName
      this.ruleForm.projectCityCode = cityObj.cityId
      getCitySelect({ cityPid: e }).then((res) => {
        this.areaList = res.data
      })
    },
    areaChange(e) {
      let countyObj = {}
      countyObj = this.areaList.find((item) => {
        return item.cityId === e
      })
      this.ruleForm.projectCountry = countyObj.cityName
      this.ruleForm.projectCountryCode = countyObj.cityId
    },
    backCar() {
      this.$router.go(-1)
    },
    changeWord(e) {
      if (this.ruleForm.workload) {
        getOffer(this.ruleForm).then((res) => {
          this.price = res.data.amountTotal.toFixed(2)
        })
      }
    },
    submit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.loading = true
          this.ruleForm.vehicleId = this.$route.query.id
          vehicleOrderSubmit(this.ruleForm).then((res) => {
            this.$message({
              message: '提交成功',
              type: 'success',
            })
            this.loading = false
            this.$router.replace('/result')
          })
        } else {
          return false
        }
      })
    },
  },
}
</script>
<style lang="scss" scoped>
::v-deep .ruleForm[data-v-7bdf0e7c] .el-form-item__error {
  left: 12%;
}
.ruleForm {
  .el-input {
    width: 50%;
  }
  ::v-deep .el-form-item__label {
    width: 135px;
  }
  .el-textarea {
    width: 50%;
  }
  .el-select {
    width: 50%;
  }
  ::v-deep .el-form-item__error {
    left: 30%;
  }
  .address_box {
    display: flex;
    .el-select {
      margin-right: 20px;
      width: 20%;
    }
  }
  .el-form-item-line {
    width: 100%;
    border-bottom: 1px solid #ddd;
  }
  .tile_box {
    border-bottom: 2px solid #dddddd;
    position: relative;
    margin-top: 40px;
    .title {
      position: absolute;
      top: -30px;
      font-size: 18px;
      font-weight: bold;
      border-bottom: 2px solid #fcb110;
      width: 110px;
      line-height: 30px;
    }
  }
}
.btn {
  display: flex;
  justify-content: right;
}
</style>
